import React, { PureComponent } from "react"
import Style from "./portfolio.module.scss"
import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { Flipper, Flipped, spring } from "react-flip-toolkit"

class Portfolio extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      filteredCategory: "none",
    }
  }

  setFilteredCategory = category => {
    this.setState({ filteredCategory: category })
  }

  getButtonStyle = category =>
    this.state.filteredCategory === category
      ? `${Style.categoryButton} ${Style.toggled}`
      : Style.categoryButton

  getButtonCloseStyle = category =>
    this.state.filteredCategory === category
      ? `${Style.categoryClose} ${Style.toggled}`
      : Style.categoryClose

  onElementAppear = (el, index) =>
    spring({
      config: "noWobble",
      onUpdate: val => {
        el.style.opacity = val
      },
      delay: index * 15,
    })

  onExit = (el, index, removeElement) => {
    spring({
      config: { overshootClamping: true },
      values: { opacity: [0, 1] },
      onUpdate: opacity => {
        el.style.opacity = opacity
      },
      delay: index * 15,
      onComplete: removeElement,
    })

    return () => {
      el.style.opacity = ""
      removeElement()
    }
  }

  handleEnterUpdateDelete = ({
    hideEnteringElements,
    animateEnteringElements,
    animateExitingElements,
    animateFlippedElements,
  }) => {
    hideEnteringElements()
    animateExitingElements()
      .then(animateFlippedElements)
      .then(animateEnteringElements)
  }

  render() {
    return (
      <div className={"container"}>
        <Flipper
          className={`${Style.animationContainer}`}
          flipKey={this.state.filteredCategory}
          spring="noWobble"
          staggerConfig={{
            default: {
              speed: 0.25,
              reverse: true,
            },
          }}
          handleEnterUpdateDelete={this.handleEnterUpdateDelete}
        >
          <div className={Style.categoriesContainer}>
            {this.props.categories.map(category => {
              return (
                <button
                  key={category.node.category}
                  className={this.getButtonStyle(category.node.category)}
                  aria-label={
                    this.state.filteredCategory !== category.node.category
                      ? category.node.category
                      : "Alle Kategorien"
                  }
                  onClick={() => {
                    if (
                      this.state.filteredCategory !== category.node.category
                    ) {
                      this.setFilteredCategory(category.node.category)
                    } else {
                      this.setFilteredCategory("none")
                    }
                  }}
                >
                  <span className={Style.categoryText}>
                    {category.node.category}
                  </span>
                  <span
                    className={this.getButtonCloseStyle(category.node.category)}
                  >
                    ✕
                  </span>
                </button>
              )
            })}
          </div>

          <div className={Style.portfolio}>
            {this.props.projects
              .filter(project => {
                if (
                  this.state.filteredCategory ===
                    project.node.category.category ||
                  this.state.filteredCategory === "none"
                ) {
                  return true
                } else {
                  return false
                }
              })
              .map(project => {
                return (
                  <Flipped
                    flipId={project.node.id}
                    key={project.node.id}
                    stagger={false}
                    onAppear={this.onElementAppear}
                    onExit={this.onExit}
                  >
                    <Link
                      to={`/projekte/${project.node.slug}`}
                      className={Style.project}
                    >
                      <BackgroundImage
                        fluid={project.node.thumbnail.fluid}
                        className={Style.image}
                      >
                        <div className={Style.overlay}>
                          <h3 className={Style.projectName}>
                            {project.node.name}
                          </h3>
                        </div>
                      </BackgroundImage>
                    </Link>
                  </Flipped>
                )
              })}
          </div>
        </Flipper>
      </div>
    )
  }
}

export default Portfolio
