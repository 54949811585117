import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import Portfolio from "../components/Portfolio"

const ProjektePage = () => {
  const {
    allContentfulProject: { projects },
    allContentfulCategory: { categories },
  } = useStaticQuery(
    graphql`
      query {
        allContentfulProject {
          projects: edges {
            node {
              id
              name
              category {
                category
              }
              slug
              thumbnail {
                title
                fluid(maxWidth: 420, quality: 50) {
                  ...GatsbyContentfulFluid
                }
                file {
                  url
                }
              }
            }
          }
        }
        allContentfulCategory {
          categories: edges {
            node {
              category
            }
          }
        }
      }
    `
  )

  return (
    <Layout>
      <SEO
        title="Projekte"
        description="Eine Übersicht unserer Projekte aus den Bereichen Ausstellung, Außenanlage, Fassade, Wohnen, Wellness, Büro, Gesundheit, Hotel, Restaurant"
      />
      <Portfolio projects={projects} categories={categories} />
    </Layout>
  )
}

export default ProjektePage
